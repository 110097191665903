
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
const itemsStatuses = [
    {id: 'new', name: 'Nowy', color: 'default'},
    {id: 'in-progress', name: 'W trakcie', color: 'primary'},
    {id: 'conditionally-approved', name: 'Warunkowo', color: 'warning'},
    {id: 'approved', name: 'Zatwierdzony', color: 'success'},
];
@Component({})
export default class AcceptanceRow extends Vue {
    @Prop(Object) public item!: any;
    @Prop(Array) public itemsHeaders!: any;

    public statusType(statusType: string) {
        return itemsStatuses.find((obj) => obj.id === statusType);
    }
}
